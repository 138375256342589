import { Collapse } from "@mui/material"
import clsx from "clsx"
import { ReactNode, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"

export type SidebarItem = {
  text: string
  icon?: ReactNode
  link?: string
  exact?: boolean
  show?: boolean
  childrens?: SidebarItem[]
  key?: string
}

export type SidebarProps = {
  items: SidebarItem[]
}
export const Sidebar = (props: SidebarProps) => {
  const { items } = props
  const location = useLocation()
  const [activatedMenu, setActivatedMenu] = useState<string | undefined>(location.pathname)
  const [openChoosingMenu, setOpenChoosingMenu] = useState<string | undefined>(undefined)

  const isActivatedMenu = (item: SidebarItem): boolean =>
    [item.key, ...(item.childrens as SidebarItem[]).map((subItem) => subItem.link)].includes(activatedMenu as string)

  const isChoosingMenu = (item: SidebarItem): boolean => [item.key].includes(openChoosingMenu as string)

  const handleActiveMenu = (key: string | undefined) =>
    activatedMenu === key ? setActivatedMenu(undefined) : setActivatedMenu(key)

  const handleOpenChoosingMenu = (key: string | undefined) =>
    openChoosingMenu === key ? setOpenChoosingMenu(undefined) : setOpenChoosingMenu(key)

  return (
    <aside>
      <ul className="aside__menu">
        {items.map((item, index) => (
          <li key={index}>
            {item.link ? (
              <NavLink
                key={index}
                to={item.link as string}
                activeClassName="is__active"
                onClick={() => {
                  handleActiveMenu(item.link)
                  handleOpenChoosingMenu(item.link)
                }}
                exact
              >
                <div className="aside__menu-icon">{item.icon}</div>
                {item.text}
              </NavLink>
            ) : (
              <>
                <div
                  className={clsx("btn-sidebar", isActivatedMenu(item) && "is__active")}
                  key={index}
                  onClick={() => handleOpenChoosingMenu(item.key)}
                >
                  <div className="aside__menu-icon">{item.icon}</div>
                  {item.text}
                </div>
                {item.childrens?.length ? (
                  <Collapse in={isActivatedMenu(item) || isChoosingMenu(item)} timeout="auto" unmountOnExit>
                    <ul className="aside__menu-sub">
                      {item.childrens.map((itemChil, indexChil) => (
                        <li key={indexChil} onClick={() => handleActiveMenu(itemChil.link)}>
                          <NavLink to={itemChil.link as string} activeClassName="is__active" exact>
                            {itemChil.text}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                ) : null}
              </>
            )}
          </li>
        ))}
      </ul>
      <div className="copyright">
        <p>© 2021 Cục Cảnh sát Phòng cháy chữa cháy và Cứu nạn cứu hộ. All Rights Reserved.</p>
        <p> Developed by CTech</p>
      </div>
    </aside>
  )
}
