/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory } from "react-router"
import { links } from "../../../../config/links"
import { Header } from "../../components/Header/Header"
import { PageTitle } from "../../components/PageTitle"
import { getCookie, removeCookie } from "../../helpers/cookies"
import { createMuiFormBinder } from "../../helpers/createMuiFormBinder"
import { setJWTCookies } from "../../helpers/setJWTCookies"
import { useAlert } from "../../hooks/useAlert"
import { useApiClient } from "../../hooks/useApiClient"
import { CookieKeys } from "../../units/enums/CookieKeys"
import { createLoginForm } from "./createLoginForm"
import { login2Fa } from "../../services/api/account"
import {useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import { set } from "date-fns"
export const Login = () => {
  const history = useHistory()
  const api = useApiClient()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [form] = useForm(createLoginForm(api))
  const bind = createMuiFormBinder(form)
  const alert = useAlert()

  useEffect(() => {
    const accessToken = getCookie(CookieKeys.AccessToken)
    if (accessToken) history.push(links.home.dashboard())
  }, [])

  const handleLogin = async () => {
    const validationErrors = await form.validate()
    if (validationErrors) return
    const res = await form.submit()
    console.log(res)
    if (res?.content) {
      if(res?.content.isUse2Fa == true){
        setShowDialog(true)
      }
      else{
        const { refreshToken, returnUrl, token } = res.content
        setJWTCookies(refreshToken, returnUrl, token)
        alert("Đăng nhập thành công", { variant: "success" })
        if (returnUrl) {
          // Redirect to returnUrl
          removeCookie(CookieKeys.ReturnUrl)
          history.push(returnUrl)
          return
        }
        history.push(links.home.dashboard())
      }
    }
     else {
      alert("Email hoặc mật khẩu không đúng", { variant: "error" })
    }
  }

  const submit = async () => {
    const formData = await form.get()
    const res = await login2Fa(api, formData)
    if(res?.content){
      const { refreshToken, returnUrl, token } = res.content
      setJWTCookies(refreshToken, returnUrl, token)
      alert("Đăng nhập thành công", { variant: "success" })
      if (returnUrl) {
        // Redirect to returnUrl
        removeCookie(CookieKeys.ReturnUrl)
        history.push(returnUrl)
        return
      }
      history.push(links.home.dashboard())
    }
    else{
      alert("Mã xác nhận không đúng", { variant: "error" })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin()
    }
  }

  return (
    <>
    <form {...bind.form()} onKeyDown={handleKeyDown}>
      <PageTitle title="Đăng nhập" />
      <Header showAvatar={false} />
      <section className="login-page">
        <div className="login-box">
          <h3>Đăng nhập</h3>
          
            <div className="form-group m-w-335">
              <label>
                Tên đăng nhập<span>*</span>
              </label>
              <input {...bind.textField("email")} />
              <FormHelperText {...bind.helperText("email")} />
            </div>
            <div className="form-group m-w-335">
              <label>
                Mật khẩu<span>*</span>
              </label>
              <input {...bind.textField("password")} type="password" />
              <FormHelperText {...bind.helperText("password")} />
            </div>
            <button disabled={form.isSubmitting()} className="btn loading btn-primary" type="button" onClick={handleLogin}>
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              Đăng Nhập
            </button>
          
        </div>
        <div>
            <Dialog
              open={showDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              disableEscapeKeyDown
            >
              <DialogTitle id="alert-dialog-title">
                Nhập mã xác nhận
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">Tài khoản của bạn đang được bảo vệ. Vui lòng nhập mã xác thực vào ô dưới đây </DialogContentText>
                <div className="form-row" style={{paddingTop:10}}>
                  <div className="form-group col-12 col-md-12">
                    <input  {...bind.textField("verifyCode")} />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowDialog(false)} className="btn btn-ct btn-back-ct btn-default-ct">
                  Hủy
                </Button>
                <button className="btn btn-custom-ct btn-primary-ct" type="button" onClick={submit} autoFocus>
                  Đăng nhập
                </button>
              </DialogActions>
            </Dialog>
          </div>
      </section>
      </form> 
    </>
  )
}
