import { AxiosInstance } from "axios"
import { ChunkedResponse } from "../../units/types/ChunkedResponse"

export const getMenuData = async (api: AxiosInstance): Promise<ChunkedResponse<any> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<any>>("/api/admin/Menu/GetMenu")
    return res.data
  } catch (err: any) {
    return err.response
  }
}
