import { CookieKeys } from "../units/enums/CookieKeys"
import { getCookie } from "./cookies"

export function getJWTCookies() {
  return {
    accessToken: getCookie(CookieKeys.AccessToken),
    refreshToken: getCookie(CookieKeys.RefreshToken),
    returnUrl: getCookie(CookieKeys.ReturnUrl),
  }
}
