
export const links = {
  oneportal: {
    logout: () => "/logout",
    login: () => "/login",
    changePassword: () => "/change-password",
    profile: () => "/profile",
    register2FA: () => "/register2FA",
    manage2FA: () => "/manage2FA",
  },

  home: {
    dashboard: () => "/",
  },

  records: {
    records: () => "/records",
    projects: () => "/projects",
    addRecord: () => "/add-record",
    addinvestor: () => "/add-record/add-investor",
    recordsList: () => "/add-record/records-list",
    editRecord: (recordId: string = ":recordId") => `/record/${recordId}`,
    changeStatus: () => "/records/change-status"
  },
  report: {
    report: () => "/report",
    monitoring: () => "/monitoring",
    project: () => "/project-approval",
    statistics: () => "/statistics-approval",
  },
  information: {
    messageReceived: () => "/messages-received",
    messageSent: () => "/messages-sent",
    sendMessage: () => "/send-message",
    messageDetail: (messageId: string = ":messageId") => `/message/${messageId}`,
  },

  category: {
    provinces: () => "/provinces",
    distrists: () => "/districts",
    communes: () => "/communes",
    projectStatus: () => "/project-status",
    projectTypes: () => "/project-types",
    groupProjects: () => "/group-projects",
    recordStatus: () => "/record-status",
    pc07: () => "/pc07",
    createPc07: () => "/pc07/create",
    updatePc07: (pc07Id: string = ":pc07Id") => `/pc07/update/${pc07Id}`,
    documentTypes: () => "/document-types",
    investors: () => "/investors",
    createInvestor: () => "/investors/create",
    updateInvestor: (investorId: string = ":investorId") => `/investors/update/${investorId}`,
    createProvince: () => "/provinces/create",
    updateProvince: (provinceId: string = ":provinceId") => `/provinces/update/${provinceId}`,
    createDistrict: () => "/districts/create",
    updateDistrict: (districtId: string = ":districtId") => `/districts/update/${districtId}`,
    createCommune: () => "/communes/create",
    updateCommune: (communeId: string = ":communeId") => `/communes/update/${communeId}`,
    createProjectType: () => "/project-types/create",
    updateProjectType: (projectTypeId: string = ":projectTypeId") => `/project-types/update/${projectTypeId}`,
    createDocumentType: () => "/document-types/create",
    updateDocumentType: (documentTypeId: string = ":documentTypeId") => `/document-types/update/${documentTypeId}`,
  },

  system: {
    listUser: () => "/users",
    resetPassword: (userId: string = ":userId") => `/users/reset-password/${userId}`,
    createUser: () => "/users/create",
    updateUser: (userId: string = ":userId") => `/users/update/${userId}`,

    listRole: () => "/roles",
    rolePermission: (roleId: string = ":roleId") => `/roles/permissions/${roleId}`,
    createRole: () => "/roles/create",
    updateRole: (roleId: string = ":roleId") => `/roles/update/${roleId}`,

    listLogHistory: () => "/history",
  },
  recordsresultprocessing: {
    recordsresultprocessing: () => "/recordsresultprocessing",
    add: () => "/add",
    importdata: () => "/importdata",
    edit: (recordsResultProcessingId: string = ":recordsResultProcessingId") => `/edit/${recordsResultProcessingId}`,
    detail: (recordsResultProcessingId: string = ":recordsResultProcessingId") => `/detail/${recordsResultProcessingId}`
  }
}
