import { AxiosInstance } from "axios"
import { ChangePasswordRequest,CheckPasswordRequest } from "../../units/types/ChangePasswordRequest"
import { ChunkedResponse } from "../../units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../units/types/ChunkedStatusResponse"
import { CurrentUser } from "../../units/types/CurrentUser"
import { QR2FA } from "../../units/types/QR2FA"
import { LoginRequest } from "../../units/types/LoginRequest"
import { LoginResponse } from "../../units/types/LoginResponse"
import { RefreshTokenRequest } from "../../units/types/RefreshToken"
import { RefreshTokenResponse } from "../../units/types/RefreshTokenResponse"
import { Enable2FARequest } from "../../units/types/Enable2FARequest"

export const enable2FA = async (
  api: AxiosInstance,
  req: Enable2FARequest
): Promise<ChunkedResponse<string> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<string>>("/api/Account/Enable2FA", req)
    return res.data
  } catch (error: any) {
    return error.response
  }
}
export const disable2Fa = async (
  api: AxiosInstance,
  req: CheckPasswordRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/Account/Disable2FA", req)
    return res.data
  } catch (error: any) {
    return error.response
  }
}
export const checkPassword = async (
  api: AxiosInstance,
  req?: CheckPasswordRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/Account/CheckPassword", req)
    return res.data
  } catch (error: any) {
    return error.response
  }
}

export const login = async (
  api: AxiosInstance,
  req: LoginRequest
): Promise<ChunkedResponse<LoginResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<LoginResponse>>("/api/Account/Login", req)
    return res.data
  } catch (error: any) {
    return error.response
  }
}
export const login2Fa = async (
  api: AxiosInstance,
  req: LoginRequest
): Promise<ChunkedResponse<LoginResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<LoginResponse>>("/api/Account/Login2FA", req)
    return res.data
  } catch (error: any) {
    return error.response
  }
}
export const removeRefreshToken = async (api: AxiosInstance, req: string | undefined): Promise<any> => {
  try {
    return await api.post<any>("/api/Account/RemoveRefreshToken", req)
  } catch (error: any) {
    return error.response
  }
}

export const refreshTokenApi = async (
  api: AxiosInstance,
  req: RefreshTokenRequest
): Promise<ChunkedResponse<RefreshTokenResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<RefreshTokenResponse>>("/api/Account/RefreshToken", req)
    return res.data
  } catch (err: any) {
    return err.response
  }
}

export const changePassword = async (
  api: AxiosInstance,
  req?: ChangePasswordRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/Account/ChangePassword", req)
    return res.data
  } catch (error: any) {
    return error.response
  }
}

export const getUserAccountDetail = async (api: AxiosInstance): Promise<ChunkedResponse<CurrentUser> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<CurrentUser>>("/api/Account/GetUserAccountDetail")
    return res.data
  } catch (error: any) {
    return error.response
  }
}
export const getQR2FA = async (api: AxiosInstance): Promise<ChunkedResponse<QR2FA> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<QR2FA>>("/api/Account/GetQR2FA")
    return res.data
  } catch (error: any) {
    return error.response
  }
}

export const updateUserAccount = async (
  api: AxiosInstance,
  data: FormData
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/Account/UpdateUserAccount", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (error: any) {
    return error.response
  }
}
