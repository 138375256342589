import cookie, { CookieAttributes } from "js-cookie"
import { DATE_EXPIRES } from "../units/constanst"
import { CookieKeys } from "../units/enums/CookieKeys"

export const getCookie = (key: CookieKeys = CookieKeys.AccessToken) => {
  return cookie.get(key)
}

export const setCookie = (
  key: CookieKeys = CookieKeys.AccessToken,
  value: any = "",
  options: CookieAttributes = { path: "/", domain: process.env.REACT_APP_DOMAIN_NAME, expires: DATE_EXPIRES }
) => {
  return cookie.set(key, value)
}

export const removeCookie = (key: CookieKeys) => {
  return cookie.remove(key)
}
