import { ReactNode, useEffect, useState } from "react"
import { CategoryIcon, HomeIcon, InformationIcon, RecordIcon, ReportIcon, SystemIcon } from "../../../assets/icons"
import { Header } from "../components/Header/Header"
import { Sidebar } from "../components/Sidebar/Sidebar"
import UserIcon from "../../../assets/image/user-icon.png"
import ChangePasswordIcon from "../../../assets/image/change-password-icon.png"
import { links } from "../../../config/links"
import { useApiClient } from "../hooks/useApiClient"
import { getMenuData } from "../services/api/menuSideBar"
import { getUserAccountDetail } from "../services/api/account"
import { useAsync } from "@corets/use-async"
import { useHistory } from "react-router"
export type MainLayoutProps = {
  children: ReactNode
}
// const data = [
//   {
//     text: "Quản lý hồ sơ",
//     link: links.fileManageMent.constructions(),
//     icon: <RecordIcon />,
//     key: "record",
//     childrens: [
//       {
//         text: "Danh sách hồ sơ",
//         link: links.records.records(),
//       },
//       {
//         text: "Danh sách công trình",
//          link: links.fileManageMent.constructions(),
//         link: links.records.projects(),
//       },
//       {
//         text: "Thêm hồ sơ",
//         link: links.records.addRecord(),
//       },
//     ],
//   },
//   {
//     text: "Báo cáo",
//     link: links.report.report(),
//     icon: <ReportIcon />,
//   },
//   {
//     text: "Trao đổi thông tin",
//     key: "infomation",
//     icon: <InformationIcon />,
//     childrens: [
//       {
//         text: "Danh sách tin đã nhận",
//         link: links.information.messageReceived(),
//       },
//       {
//         text: "Danh sách tin đã gửi",
//         link: links.information.messageSent(),
//       },
//       {
//         text: "Gửi tin",
//         link: links.information.sendMessage(),
//       },
//     ],
//   },
//   {
//     text: "Quản lý danh mục",
//     key: "category",
//     icon: <CategoryIcon />,
//     childrens: [
//       {
//         text: "Tỉnh thành",
//         link: links.category.provinces(),
//       },
//       {
//         text: "Quân Huyện",
//         link: links.category.distrists(),
//       },
//       {
//         text: "Phường Xã",
//         link: links.category.communes(),
//       },
//       {
//         text: "Trạng thái hồ sơ",
//         link: links.category.recordStatus(),
//       },
//       {
//         text: "Trạng thái công trình",
//         link: links.category.projectStatus(),
//       },
//       {
//         text: "Loại công trình",
//         link: links.category.projectTypes(),
//       },
//       {
//         text: "Nhóm công trình",
//         link: links.category.groupProjects(),
//       },
//       {
//         text: "Phòng CSPCCC&CNCH địa phương",
//         link: links.category.pc07(),
//       },
//       {
//         text: "Loại tài liệu",
//         link: links.category.documentTypes(),
//       },
//       {
//         text: "Nhà đầu tư",
//         link: links.category.investors(),
//       },
//     ],
//   },
//   {
//     text: "Hệ thống",
//     key: "system",
//     icon: <SystemIcon />,
//     childrens: [
//       {
//         text: "Danh sách user",
//         link: links.system.listUser(),
//       },
//       {
//         text: "Nhóm quyền",
//         link: links.system.listRole(),
//       },
//     ],
//   },

//   {
//     text: "Trang chủ",
//     link: links.home.dashboard(),
//     icon: <HomeIcon />,
//   },
// ]

const mapMenuArray = (arr: []) => {
  if (Array.isArray(arr)) {
    const newArr = arr.map((item: any) => {
      if (item?.code === "DOCUMENT") {
        return {
          text: item?.title,
          icon: <RecordIcon />,
          key: "record",
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "RECORDS":
                return {
                  text: sub?.title,
                  link: links.records.records(),
                }
              case "PROJECT":
                return {
                  text: sub?.title,
                  link: links.records.projects(),
                }
              case "CREATERECORDS":
                return {
                  text: sub?.title,
                  link: links.records.addRecord(),
                }
              default:
                return {}
            }
          }),
        }
      }
      if (item?.code === "REPORT") {
        return {
          text: item?.title,
          link: links.report.report(),
          icon: <ReportIcon />,
        }
      }
      if (item?.code === "MESSAGE") {
        return {
          text: item?.title,
          key: "infomation",
          icon: <InformationIcon />,
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "MESSAGEHASSEND":
                return {
                  text: sub?.title,
                  link: links.information.messageSent(),
                }
              case "MESSAGEHASRECEIVE":
                return {
                  text: sub?.title,
                  link: links.information.messageReceived(),
                }
              case "SENDMESSAGE":
                return {
                  text: sub?.title,
                  link: links.information.sendMessage(),
                }
              default:
                return {}
            }
          }),
        }
      }
      if (item?.code === "COMMON") {
        return {
          text: item?.title,
          key: "category",
          icon: <CategoryIcon />,
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "PROVINCE":
                return {
                  text: sub?.title,
                  link: links.category.provinces(),
                }
              case "DISTRICT":
                return {
                  text: sub?.title,
                  link: links.category.distrists(),
                }
              case "COMMUNE":
                return {
                  text: sub?.title,
                  link: links.category.communes(),
                }
              case "PC07LOCAL":
                return {
                  text: sub?.title,
                  link: links.category.pc07(),
                }
              case "CONSTRUCTIONTYPE":
                return {
                  text: sub?.title,
                  link: links.category.projectTypes(),
                }
              case "INVESTOR":
                return {
                  text: sub?.title,
                  link: links.category.investors(),
                }
              case "ATTACHFILETYPE":
                return {
                  text: sub?.title,
                  link: links.category.documentTypes(),
                }
              default:
                return {}
            }
          }),
        }
      }
      if (item?.code === "RECORDSRESULTPROCESSING") {
        return {
          text: item?.title,
          key: "recordsresult",
          //link: links.recordsresultprocessing.recordsresultprocessing(),
          icon: <ReportIcon />,
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "RECORDSRESULTPROCESSING_1":
                return {
                  text: sub?.title,
                  link: links.recordsresultprocessing.recordsresultprocessing(),
                }
              case "IMPORTDATA":
                return {
                  text: sub?.title,
                  link: links.recordsresultprocessing.importdata(),
                }
              default:
                return {}
            }
          }),
        }
      }

      return {}
    })
    return newArr
  }
  return []
}

const validateMenu = (menu) => {
  return menu.filter((m) => Object.keys(m).length !== 0)
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props
  const api = useApiClient()
  const [menu, setMenu] = useState([] as any)
  const history = useHistory()
  const menuData = useAsync(() => getMenuData(api), []).getResult()?.content
  const userInfo = useAsync(() => getUserAccountDetail(api), []).getResult()?.content

  useEffect(() => {
    if(userInfo && !userInfo.isUse2FA){
      history.push(links.oneportal.register2FA())
    }
    if (menuData && userInfo && userInfo.isUse2FA) {
      let menu = [
        {
          text: "Trang chủ",
          link: links.home.dashboard(),
          icon: <HomeIcon />,
        },
        ...mapMenuArray(menuData),
      ]
      if (userInfo?.roleName === "ADMIN")
        console.log('admin')
        menu.push({
          text: "Hệ thống",
          key: "system",
          icon: <SystemIcon />,
          childrens: [
            {
              text: "Danh sách user",
              link: links.system.listUser(),
            },
            {
              text: "Nhóm quyền",
              link: links.system.listRole(),
            },
            {
              text: "Lịch sử hoạt động",
              link: links.system.listLogHistory(),
            },
          ],
        })

      menu = validateMenu(menu)
      setMenu(menu)
    }
  }, [menuData,userInfo])

  return (
    <>
      <Header
        items={[
          {
            text: "Thông tin tài khoản",
            link: links.oneportal.profile(),
            icon: UserIcon,
          },
          {
            text: "Đặt lại mật khẩu",
            link: links.oneportal.changePassword(),
            icon: ChangePasswordIcon,
          },
        ]}
      />
      <main>
        <div className="wrap-page d-flex">
          <Sidebar items={menu} />
          {children}
        </div>
      </main>
    </>
  )
}
