import React from "react"
import { render } from "react-dom"
import { App } from "./app/App"
import reportWebVitals from "./reportWebVitals"
import "./app/assets/style/common.css"
import "./app/assets/style/home.css"
import "./app/assets/style/qlhs.css"
import "./app/assets/style/report.css"
import "./app/assets/style/tdtt.css"

export const Root = () => {
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}
render(<Root />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
