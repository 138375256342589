import { lazy } from "react"
import { RouteProps } from "react-router"
import { links } from "./links"

export const routes: RouteProps[] = [
  {
    path: links.home.dashboard(),
    component: lazy(() => import("../modules/home/Dashboard")),
    exact: true,
  },
  {
    path: links.report.report(),
    component: lazy(() => import("../modules/report/Report")),
    exact: true,
  },
  {
    path: links.report.monitoring(),
    component: lazy(() => import("../modules/report/pages/Monitoring/Monitoring")),
  },
  {
    path: links.report.project(),
    component: lazy(() => import("../modules/report/pages/Approve/Approve")),
  },
  {
    path: links.report.statistics(),
    component: lazy(() => import("../modules/report/pages/Statistics/Statistics")),
  },
  {
    path: links.oneportal.changePassword(),
    component: lazy(() => import("../modules/oneportal/pages/ChangePassword/ChangePassword")),
  },
  {
    path: links.oneportal.profile(),
    component: lazy(() => import("../modules/oneportal/pages/Profile/Profile")),
  },
  {
    path: links.oneportal.register2FA(),
    component: lazy(() => import("../modules/oneportal/pages/Setting/Register2FA")),
  },
  {
    path: links.oneportal.manage2FA(),
    component: lazy(() => import("../modules/oneportal/pages/Setting/Manage2FA")),
  },
  {
    path: links.records.addRecord(),
    component: lazy(() => import("../modules/records/pages/CreateOrEditRecordForm/CreateOrUpdateRecordForm")),
  },
  {
    path: links.records.editRecord(),
    component: lazy(() => import("../modules/records/pages/CreateOrEditRecordForm/CreateOrUpdateRecordForm")),
  },
  {
    path: links.records.records(),
    component: lazy(() => import("../modules/records/pages/ListRecord/ListRecord")),
  },
  {
    path: links.records.projects(),
    component: lazy(() => import("../modules/records/pages/ListProject/ListProject")),
  },
  {
    path: links.information.sendMessage(),
    component: lazy(() => import("../modules/Message/pages/SendMessage/SendMessage")),
  },
  {
    path: links.information.messageSent(),
    component: lazy(() => import("../modules/Message/pages/MessageSent/MessageSent")),
  },
  {
    path: links.information.messageReceived(),
    component: lazy(() => import("../modules/Message/pages/MessageReceive/MessageReceive")),
  },
  {
    path: links.information.messageDetail(),
    component: lazy(() => import("../modules/Message/pages/MessageDetail/MessageDetail")),
  },
  {
    path: links.system.listUser(),
    component: lazy(() => import("../modules/system/pages/ListUser")),
  },
  {
    path: links.system.listRole(),
    component: lazy(() => import("../modules/system/pages/ListRole")),
  },
  {
    path: links.system.listLogHistory(),
    component: lazy(() => import("../modules/system/pages/ListLogHistory")),
  },
  {
    path: links.category.investors(),
    component: lazy(() => import("../modules/category/pages/ListInvestor")),
  },
  {
    path: links.category.provinces(),
    component: lazy(() => import("../modules/category/pages/ListProvince")),
  },
  {
    path: links.category.distrists(),
    component: lazy(() => import("../modules/category/pages/ListDistrict")),
  },
  {
    path: links.category.communes(),
    component: lazy(() => import("../modules/category/pages/ListCommune")),
  },
  {
    path: links.category.pc07(),
    component: lazy(() => import("../modules/category/pages/ListPC07")),
  },
  {
    path: links.category.projectTypes(),
    component: lazy(() => import("../modules/category/pages/ListProjectType")),
  },
  {
    path: links.category.documentTypes(),
    component: lazy(() => import("../modules/category/pages/ListDocumentType")),
  },
  {
    path: links.recordsresultprocessing.recordsresultprocessing(),
    component: lazy(() => import("../modules/recordsresultprocessing/pages/ListRecordResultProcessing/ListRecordResultProcessing")),
  },
  {
    path: links.recordsresultprocessing.importdata(),
    component: lazy(() => import("../modules/recordsresultprocessing/pages/ImportFile/ImportFile")),
  },
  {
    path: links.recordsresultprocessing.add(),
    component: lazy(() => import("../modules/recordsresultprocessing/pages/CreateOrEdit/CreateOrEditRecordsResultProcessing")),
  },
  {
    path: links.recordsresultprocessing.edit(),
    component: lazy(() => import("../modules/recordsresultprocessing/pages/CreateOrEdit/CreateOrEditRecordsResultProcessing")),
  },
  {
    path: links.recordsresultprocessing.detail(),
    component: lazy(() => import("../modules/recordsresultprocessing/pages/Detail/Detail")),
  },
]
