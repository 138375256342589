import AdminIcon from "../../../../assets/image/admin.svg"
import LogoutIcon from "../../../../assets/image/logout-icon.png"
import Logo from "../../../../assets/image/logo.png"
import DownIcon from "../../../../assets/image/down.png"
import { useState } from "react"
import clsx from "clsx"
import { NavLink } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"
import { getCookieInfo } from "../../helpers/getCookieInfo"
import { CurrentUser } from "../../units/types/CurrentUser"
import { readImageFile } from "../../helpers/readImageFile"
import ClickAwayListener from "@mui/base/ClickAwayListener"

export type NavbarLink = {
  text: string
  icon?: string
  link: string
}
export type HeaderProps = {
  showAvatar?: boolean
  items?: NavbarLink[]
}

export const Header = (props: HeaderProps) => {
  const { showAvatar = true, items } = props
  const auth = useAuth()
  const userInfo = getCookieInfo() as CurrentUser

  const [openPopup, setOpenPopup] = useState(false)

  const handleOpenPopup = () => {
    setOpenPopup(!openPopup)
  }

  const handleLogout = async () => await auth.logout()

  return (
    <header>
      <div className="header__inner d-flex align-items-center justify-content-between">
        <div className="header__logo d-flex align-items-center">
          <a href="index.html">
            <img src={Logo} alt="" />
          </a>
          <h1 className="site-title only-pc">Phần mềm quản lý hồ sơ thẩm duyệt thiết kế phòng cháy chữa cháy</h1>
        </div>
        {showAvatar && (
          <ClickAwayListener onClickAway={() => setOpenPopup(false)}>
            <div className="header__admin d-flex align-items-center" onClick={handleOpenPopup}>
              <img className="avatar" src={readImageFile(userInfo.avatar, AdminIcon)} alt="" />
              <div className="admin__text d-flex align-items-center">
                <p>{userInfo.fullName}</p>
                <img src={DownIcon} alt="" />
              </div>

              <ul className={clsx("admin-popup", openPopup && "is__open")}>
                {items?.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.link}>
                      <img src={item.icon} alt="" />
                      {item.text}
                    </NavLink>
                  </li>
                ))}
                <li>
                  <div className="logout" onClick={handleLogout}>
                    <img src={LogoutIcon} alt="" />
                    Đăng xuất
                  </div>
                </li>
              </ul>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </header>
  )
}
